<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('Bases1C')"
    @cancel="onCancel('Bases1C')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'Bases1CCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Bases1C',
      pageHeader: 'Создание базы 1С',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Базы 1С',
          route: { name: 'Bases1C' },
        },
        {
          text: 'Создание базы 1С',
        },
      ],
      initialForm: {
        // created: null, // read only
        customerId: null, // add only
        name: null, // add only
        createDemo: false, // add only
        text: null,
        baseTypeId: null,
        isServerBase: false,
        // releaseNum: null, // edit only
        // autoupdate: null // edit only
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      customers: (state) => state.common.customers,
      bases1CTypes: (state) => state.common.bases1CTypes,
      // bases1CUpdateTypes: (state) => state.common.bases1CUpdateTypes,
    }),

    fields() {
      return [
        {
          form: [
            // {
            //   type: 'created',
            //   key: 'text',
            //   label: 'Дата создания',
            // },
            {
              type: 'select',
              key: 'customerId',
              label: 'Клиент',
              list: this.customers.data, // Common/Customers
            },
            {
              type: 'text',
              key: 'name',
              label: 'Имя базы (так будет названа папка на диске)',
            },
            {
              type: 'checkbox',
              key: 'createDemo',
              label: 'Загрузить демонстрационные данные',
            },
            {
              type: 'text',
              key: 'text',
              label: 'Отображаемое имя (так будет называться в списке баз)',
            },
            {
              type: 'select',
              key: 'baseTypeId',
              label: 'Тип информационной базы',
              list: this.bases1CTypes.data, // Common/Bases1CTypes
            },
            {
              type: 'checkbox',
              key: 'isServerBase',
              label: 'Серверная база',
            },
            // {
            //   type: 'text',
            //   key: 'releaseNum',
            //   label: 'Релиз',
            // },
            // {
            //   type: 'select',
            //   key: 'autoupdate',
            //   label: 'Обновление',
            //    list: this.bases1CUpdateTypes.data // Common/Bases1CUpdateTypes
            // },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const customers = this.getCommonList({ name: 'Customers' });
    const bases1CTypes = this.getCommonList({ name: 'Bases1CTypes' });
    // const bases1CUpdateTypes = this.getCommonList({ name: 'Bases1CUpdateTypes' });

    Promise.all([customers, bases1CTypes]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
